function formatTree(data: _CategoryStateItem[], parentId: number | string = 0): _CategoryStateItem[] {
  return data.filter(item => item.parentId === parentId).map((item) => {
    return {
      ...item,
      children: formatTree(data, item.id),
    }
  })
}

function treeToList(tree: any[], option = { children: 'children' }) {
  const list = []
  const queue = [...tree]
  while (queue.length) {
    const item = queue.shift()
    if (item[option.children]?.length > 0) {
      queue.push(...item[option.children])
    }
    list.push(item)
  }
  return list
}

export function useCategoryStore() {
  const other: _CategoryStateItem[] = [
    {
      adPicId: 'HOT',
      id: 'F20', // 熱賣
      name: '熱賣品',
      nameEn: 'Hot',
      code: 'F20',
      picture: '',
      parentId: 0,
      level: 0,
      parentName: '',
      publishStatus: 1,
      signStatus: 1,
      sort: 1,
      status: 1,
      type: 1,
      icon: 'hot',
    },
    {
      adPicId: 'SALE',
      id: 'F21', // 特價
      name: '特價品',
      nameEn: 'SALE',
      code: 'F21',
      picture: '',
      parentId: 0,
      level: 0,
      parentName: '',
      publishStatus: 1,
      signStatus: 1,
      sort: 1,
      status: 1,
      type: 1,
      icon: 'special',
    },
  ]

  const categories = useState<_CategoryStateItem[]>('Categories', () => [])

  /** 节日 */
  const holidayStore = useState<any[]>('_HolidayStore', () => [])

  const holidayList = computed(() => {
    return treeToList(holidayStore.value, { children: 'childrenList' })
  })

  const topCategories = computed(() => {
    return categories.value.filter(item => item.parentId === 0)
  })

  const subCategories = (level: number) => {
    return categories.value.filter(item => item.level === level)
  }

  const flatCategories = computed(() => {
    return categories.value
  })

  const getCategories = computed(() => {
    return formatTree(categories.value)
  })

  /** 熱賣品 特價品 */
  const otherCategories = computed<_CategoryStateItem[]>(() => {
    return other
  })

  const categoriesComputed = computed<_CategoryStateItem[]>(() => {
    const categories = topCategories.value.map(item => ({
      ...item,
      nameEn: item.nameEn || item.name,
    }))

    return [...categories]
  })

  return {
    holidayStore,
    holidayList,
    categories,
    topCategories,
    otherCategories,
    subCategories,
    flatCategories,
    getCategories,
    categoriesComputed,
  }
}
